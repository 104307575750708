import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";

import "swiper/swiper-bundle.css";

import "./App.scss";
import ContactForm from "./components/Contact";

SwiperCore.use([Navigation, Autoplay]);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: null,
      isOpen: false,
      isOpen2: false,
      currentYear: new Date().getFullYear()
    };
  }


  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  openModal2 = () => this.setState({ isOpen2: true });
  closeModal2 = () => this.setState({ isOpen2: false });

  componentDidMount() {
    document
      .querySelector("body")
      .style.setProperty(
        "--primary",
        this.state.company ? this.state.company.color : ""
      );

    let id = window.location.pathname.replace("/", "");

    setTimeout(() => {
      window.$(".js-scroll-trigger").click(function () {
        window.$(".navbar-collapse").collapse("hide");
      });

      // Activate scrollspy to add active class to navbar items on scroll
      window.$("body").scrollspy({
        target: "#mainNav",
        offset: 100,
      });

      // Collapse Navbar
      var navbarCollapse = function () {
        if (window.$("#mainNav").offset().top > 100) {
          window.$("#mainNav").addClass("navbar-shrink");
        } else {
          window.$("#mainNav").removeClass("navbar-shrink");
        }
      };
      // Collapse now if page is not at top
      navbarCollapse();
      // Collapse the navbar when page is scrolled
      window.$(window).scroll(navbarCollapse);
    }, 1000);
  }

  render() {
    let logo = "";
    if (this.state.company && this.state.company.logo) {
      logo = this.state.company.logo;
    }

    return (
      <React.Fragment>
        <div>
          <nav
            className="navbar navbar-expand-lg navbar-light fixed-top"
            id="mainNav"
          >
            <div className="container-xl">
              <a className="navbar-brand js-scroll-trigger" href="#home">
                <img
                  className="logo-white"
                  height={58}
                  src="../assets/images/itzlogo-new.svg"
                />
                <img
                  className="logo-dark"
                  height={58}
                  src="../assets/images/itzlogo-new.svg"
                />
              </a>

              <button
                className="navbar-toggler navbar-toggler-right"
                type="button"
                data-toggle="collapse"
                data-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className=" small fas fa-bars" />
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  <li
                    onMouseOut={(e) => {
                      e.target.style.color = "";
                    }}
                    onMouseOver={(e) => {
                      e.target.style.color = this.state.company
                        ? this.state.company.color
                        : "";
                    }}
                    className="nav-item"
                  >
                    <a className="nav-link js-scroll-trigger" href="#whyus">
                      Why Us
                    </a>
                  </li>
                  <li
                    onMouseOut={(e) => {
                      e.target.style.color = "";
                    }}
                    onMouseOver={(e) => {
                      e.target.style.color = this.state.company
                        ? this.state.company.color
                        : "";
                    }}
                    className="nav-item"
                  >
                    <a className="nav-link js-scroll-trigger" href="#about">
                      About
                    </a>
                  </li>
                  <li
                    onMouseOut={(e) => {
                      e.target.style.color = "";
                    }}
                    onMouseOver={(e) => {
                      e.target.style.color = this.state.company
                        ? this.state.company.color
                        : "";
                    }}
                    className="nav-item"
                  >
                    <a className="nav-link js-scroll-trigger" href="#history">
                      History
                    </a>
                  </li>
                  <li
                    onMouseOut={(e) => {
                      e.target.style.color = "";
                    }}
                    onMouseOver={(e) => {
                      e.target.style.color = this.state.company
                        ? this.state.company.color
                        : "";
                    }}
                    className="nav-item"
                  >
                    <a className="nav-link js-scroll-trigger" href="#contact">
                      Contact
                    </a>
                  </li>

                  <li>
                    <a
                      style={{
                        background: this.state.company
                          ? this.state.company.color
                          : "",
                        borderColor: this.state.company
                          ? this.state.company.color
                          : "",
                      }}
                      className="btn btn-header ml-2"
                      href="javascript:void(0)"
                      onClick={this.openModal}
                    >
                      Driver Application
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      style={{
                        background: this.state.company
                          ? this.state.company.color
                          : "",
                        borderColor: this.state.company
                          ? this.state.company.color
                          : "",
                      }}
                      className="btn btn-header-outline ml-2"
                      href="https://app.portpro.io/"
                    >
                      Customer Login
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <header
            id="masthead"
            className="masthead"
            style={{
              backgroundImage: 'url("../assets/images/container-index.jpg")',
            }}
          >
            <div className="container d-flex h-100 align-items-center">
              <div className="row">
                <div className="text-white masthead__wrap col-md-6">
                  <h5 class="section-title">We are Itz OHLSON</h5>
                  <h1 className="mx-auto mt-2 text-white display-4 main-title section-subtitle">
                    A Leading Intermodal Drayage{" "}
                    <span class="portpro-dot dot-primary">Carrier</span>
                  </h1>
                  <p className="text-white text-description">
                    We provide trucking services to Importers and Exporters in
                    Eastern New England via the Ports of Boston and New York/New
                    Jersey.
                  </p>
                </div>
                <div
                  className="col-md-6 d-flex align-items-center justify-content-center"
                  style={{ zIndex: 1 }}
                >
                  <a
                    style={{
                      background: this.state.company
                        ? this.state.company.color
                        : "",
                      borderColor: this.state.company
                        ? this.state.company.color
                        : "",
                      padding: "30px 60px",
                      fontSize: 20,
                    }}
                    className="btn btn-main btn-lg"
                    href="javascript:void(0)"
                    onClick={this.openModal2}
                  >
                    Get a Rate Quote
                  </a>
                  {/* <div className="masthead__btngrp">
                    <div className="form-group">
                      <select name="" id="" className="section-cta__input">
                        <option value="">Select Port</option>
                        <option value="">Maher</option>
                        <option value="">APT</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <input type="text" className="section-cta__input" placeholder="Type the address" />
                    </div>
                    <div className="form-group mb-0">
                      <a style={{ background: this.state.company ? this.state.company.color : '', borderColor: this.state.company ? this.state.company.color : '' }} className="btn btn-main btn-lg d-flex align-items-center h-100 justify-content-center" href="#about">Get a Rate Quote</a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </header>
          <section className="section-cta">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <h5 className="section-cta__title">Get In Touch</h5>
                  <p className="section-cta__text">
                    <a href="tel:+ 1 617 387-1541" style={{ color: "inherit" }}>
                      + 1 617 387-1541 (o)
                    </a>{" "}
                    <a href="tel:+ 1 781 587-1689" style={{ color: "inherit" }}>
                      + 1 781 587-1689 (f)
                    </a>
                  </p>
                </div>
                <div className="col-lg-4">
                  <h5 className="section-cta__title">Our Location</h5>
                  <p className="section-cta__text">
                    PO Box 129 Topsfield, MA 01983
                  </p>
                </div>
                <div className="col-lg-4">
                  <a
                    class="btn btn-header ml-2 d-block"
                    href="javascript:void(0)"
                    onClick={this.openModal}
                  >
                    Driver Application
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-100 pt-150" id="whyus">
            <div className="container">
              <div className="text-center mb-100 mx-auto text-center">
                <h5 className="section-title mb-50">Why Us</h5>
                <h2 className="section-subtitle">
                  Voted a{" "}
                  <a
                    href="https://ajot.com/premium/ajot-ajots-top-100-north-american-trucking-companies-2020"
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    Top 100 Trucking Company
                  </a>{" "}
                  <br />
                  by AJOT for 2019 &{" "}
                  <span className="portpro-dot dot-primary">2020</span>
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="section-service1__col">
                    <img
                      src="../assets/images/artboard1.png"
                      className="section-service1__image"
                      alt=""
                    />
                    <h4 className="section-service1__title">
                      Technology Enabled
                    </h4>
                    <p className="section-service1__desc">
                      We use industry leading technology that allows us to
                      operate efficiently. We give our customers a portal where
                      they can monitor vessel ETA, receive alerts (holds,
                      customs, freight), confirm port availability/LFD, view
                      container status, view driver locations, and access
                      outstanding/paid invoices.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="section-service1__col">
                    <img
                      src="../assets/images/undraw4.svg"
                      className="section-service1__image"
                      alt=""
                    />
                    <h4 className="section-service1__title">
                      Competitive Rates
                    </h4>
                    <p className="section-service1__desc">
                      Through technology, we've created efficiency. We are able
                      to plan our routes, maximize driver time, and cut down on
                      unnecessary expenses which allows us to offer competitive
                      rates to our customers.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="section-service1__col">
                    <img
                      src="../assets/images/undraw3.svg"
                      className="section-service1__image"
                      alt=""
                    />
                    <h4 className="section-service1__title">
                      Industry Experience
                    </h4>
                    <p className="section-service1__desc">
                      With over 20 years on the road, we think it's safe to say
                      we know a thing or two about trucking! Our experience
                      allows us to navigate the complexities of the Ports in
                      which we service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-scta mt-100">
            <div className="container">
              <div className="d-flex align-items-center justify-content-center">
                <div className="section-scta__text">
                  <p className="section-scta__title">
                    We’re here to meet all your drayage and logistics needs.
                  </p>
                </div>
                <div className="section-scta__links">
                  <a
                    href="javascript:void(0)"
                    onClick={this.openModal2}
                    className="section-scta__link"
                    aria-label="Get a Quote for Your Business"
                  >
                    Get a Rate Quote<i className="uil uil-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="position-relative pt-100" id="about">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  className="leftside-image"
                  src="../assets/images/leftside-image.png"
                />
              </div>
              <div className="col-lg-5 ">
                <h5 className="section-title">About Us</h5>
                <h2 className="section-subtitle mb-20">
                  We know the Ports of Boston and{" "}
                  <span class="portpro-dot dot-primary">NY/NJ</span>
                </h2>
                <p className="mb-20">
                  Itz-Ohlson Transport is a top 100 rated intermodal carrier
                  that operates in these ports on a daily basis. Our drivers are
                  owner operators with TWIC cards (the proper port clearances)
                  and RFID tags. All of our vehicles are GPS equipped. We have a
                  mix of chassis; some are tri-axle, some are 40' goosenecks and
                  of course, we lease chassis from the chassis pool operators to
                  provide capacity when and where it is needed.
                </p>
                <a
                  href="javascript:void(0)"
                  onClick={this.openModal2}
                  className="btn btn-header"
                >
                  Get A Rate
                </a>
              </div>
            </div>
          </section>

          <section className="section-modern pt-150" id="history">
            <div className="container-xl">
              <div className="section-modern__wrap">
                <Swiper
                  slidesPerView={1}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  className="section-modern__media"
                  autoplay={{ delay: 5000 }}
                  loop
                >
                  <SwiperSlide>
                    <div
                      className="section-modern__inner"
                      style={{
                        backgroundImage: 'url("../assets/images/history1.jpg")',
                        backgroundPosition: "top center",
                      }}
                    ></div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="section-modern__inner"
                      style={{
                        backgroundImage: 'url("../assets/images/history2.jpg")',
                        backgroundPosition: "top center",
                      }}
                    ></div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="section-modern__inner"
                      style={{
                        backgroundImage: 'url("../assets/images/history3.jpg")',
                        backgroundPosition: "top center",
                      }}
                    ></div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="section-modern__inner"
                      style={{
                        backgroundImage: 'url("../assets/images/history4.jpg")',
                        backgroundPosition: "top center",
                      }}
                    ></div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="section-modern__inner"
                      style={{
                        backgroundImage:
                          'url("http://itz-ohlson.com/uploads/77/2066.jpg")',
                        backgroundPosition: "top center",
                      }}
                    ></div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="section-modern__inner"
                      style={{
                        backgroundImage:
                          'url("http://itz-ohlson.com/uploads/77/2654.jpg")',
                        backgroundPosition: "top center",
                      }}
                    ></div>
                  </SwiperSlide>
                </Swiper>
                <div className="section-modern__wrapper">
                  <div className="section-modern__inner_wrapper">
                    <h2 className="section-modern__title">Our History</h2>
                    <p className="section-modern__text">
                      Itz-Ohlson Transport Inc. was founded in 1991 by Charles
                      Itz and James Ohlson. Prior to that, both Itz and Ohlson
                      had experience in the trucking business: Itz worked for a
                      steamship agent/steamship line and Ohlson was with a
                      broker/forwarder in the Port of Boston. Sadly, Jimmy
                      Ohlson passed away far too young in 1995 but hard work,
                      perseverance and our satisfied customers have kept
                      Itz-Ohlson Transport on the road for 20 more years. We're
                      looking forward to another 20 years, one container at a
                      time! Jimmy is deeply missed and remembered here every
                      day!
                    </p>
                  </div>
                  <img
                    src="./assets/images/service1.png"
                    alt=""
                    className="section-modern__imgbottom d-none d-lg-block"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="section-contact pt-250 pb-150" id="contact">
            <div className="container">
              <div className="row d-flex align-items-center flex-column-reverse flex-lg-row">
                <div className="col-lg-6">
                  <ContactForm />
                </div>
                <div className="col-lg-6">
                  <div className="section-contact__info">
                    <p className="section-contact__desc">
                      To reach out, please submit this form or contact one of
                      our team members/departments below!
                    </p>
                  </div>
                  <div className="mb-40">
                    <div className="form-group section-contact__emails">
                      <h5 className="mb-0">Dispatch:</h5>
                      <a className="text-muted" href="mailto:dispatch@itzo.net">dispatch@itzo.net</a>
                    </div>
                    <div className="form-group section-contact__emails">
                      <h5 className="mb-0">Billing:</h5>
                      <a className="text-muted" href="mailto:billing@itzo.net">billing@itzo.net</a>
                    </div>
                    <div className="form-group section-contact__emails">
                      <h5 className="mb-0">Payables:</h5>
                      <a className="text-muted" href="mailto:payables@itzo.net">payables@itzo.net</a>
                    </div>
                    <div className="form-group section-contact__emails">
                      <h5 className="mb-0">Safety:</h5>
                      <a className="text-muted" href="mailto:safety@itzo.net">safety@itzo.net</a>
                    </div>
                    <div className="form-group section-contact__emails">
                      <h5 className="mb-0">Rates:</h5>
                      <a className="text-muted" href="mailto:rates@itzo.net">rates@itzo.net</a>
                    </div>
                    <div className="form-group section-contact__emails">
                      <h5 className="mb-0">Employement:</h5>
                      <a className="text-muted" href="mailto:jobs@itzo.net">jobs@itzo.net</a>
                    </div>
                    <div className="form-group section-contact__emails">
                      <h5 className="mb-0">Charlie Itz:</h5>
                      <a className="text-muted" href="mailto:charlie@itzo.net">charlie@itzo.net</a>
                    </div>
                    <div className="form-group section-contact__emails">
                      <h5 className="mb-0">Jacob Itz:</h5>
                      <a className="text-muted" href="mailto:jacob@itzo.net">jacob@itzo.net</a>
                    </div>
                    <div className="form-group section-contact__emails">
                      <h5 className="mb-0">Michelle Furbush:</h5>
                      <a className="text-muted" href="mailto:michelle@itzo.net">michelle@itzo.net</a>
                    </div>
                    <div className="form-group section-contact__emails">
                      <h5 className="mb-0">Gina De La Rosa:</h5>
                      <a className="text-muted" href="mailto:gina@itzo.net">gina@itzo.net</a>
                    </div>
                  </div>
                  <div className="section-contact__address">
                    <div className="section-contact__iconwrap">
                      <i className="section-contact__icon uil uil-map-marker-alt"></i>
                      <p className="section-contact__icontext mb-0">
                        PO Box 129 Topsfield, MA 01983
                      </p>
                    </div>
                    <div className="section-contact__iconwrap">
                      <i className="section-contact__icon uil uil-phone"></i>
                      <p className="section-contact__icontext mb-0">
                        <a
                          href="tel:+ 1 617 387-1541"
                          style={{ color: "inherit" }}
                        >
                          + 1 617 387-1541 (o)
                        </a>{" "}
                        <a
                          href="tel:+ 1 781 587-1689"
                          style={{ color: "inherit" }}
                        >
                          + 1 781 587-1689 (f)
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="section-socials-container d-flex align-items-center mt-45">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/Itz-Ohlson-Transport-389681191162682/"
                      className="section-socials__link mr-4"
                    >
                      <i className="uil uil-facebook-f"></i>
                      facebook
                    </a>

                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/itz-ohlson-transport-inc./"
                      className="section-socials__link"
                    >
                      <i className="uil uil-linkedin"></i>
                      linkedin
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

         

          <section className="section-cta-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 d-flex align-items-center justify-content-center">
                  <h4 className="section-cta-bottom__title text-white mb-0">
                    Fill out a driver application and join the Itz Ohlson team.
                  </h4>
                  <a
                    href="javascript:void(0)"
                    className="section-cta-bottom__button ml-4 btn"
                    onClick={this.openModal}
                  >
                    Driver Application
                  </a>
                </div>
              </div>
            </div>
          </section>
          <footer className="main-footer">
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <img
                      className="logo-white mb-40"
                      height="58"
                      src="../assets/images/itzlogo-new.svg"
                    />
                    <p>PO Box 129 Topsfield, MA 01983</p>
                    <p>
                      <a
                        href="tel:+ 1 617 387-1541"
                        style={{ color: "inherit" }}
                      >
                        + 1 617 387-1541 (o)
                      </a>{" "}
                      <a
                        href="tel:+ 1 781 587-1689"
                        style={{ color: "inherit" }}
                      >
                        + 1 781 587-1689 (f)
                      </a>
                    </p>
                    <ul className="main-footer__sociallinks d-flex align-items-center">
                      <li className="mr-2">
                        <a
                          target="_blank"
                          href="https://www.facebook.com/Itz-Ohlson-Transport-389681191162682/"
                        >
                          <i className="uil uil-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/company/itz-ohlson-transport-inc./"
                        >
                          <i className="uil uil-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3">
                    <h3 className="widget-title text-white">Learn More</h3>
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="#whyus">Why Us</a>
                      </li>
                      <li>
                        <a href="#about">About</a>
                      </li>
                      <li>
                        <a href="#history">History</a>
                      </li>
                      <li>
                        <a href="#contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-5">
                    <h3 className="widget-title text-white">
                      Get a Rate Quote
                    </h3>
                    <a
                      style={{
                        background: this.state.company
                          ? this.state.company.color
                          : "",
                        borderColor: this.state.company
                          ? this.state.company.color
                          : "",
                      }}
                      className="btn btn-main btn-lg mt-3"
                      href="javascript:void(0)"
                      onClick={this.openModal2}
                    >
                      Get A Quote Here
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section className="pt-35 pb-35 mt-50 bt-fade-white-015">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <p className="my-0">
                      <span style={{ fontSize: "15px" }}>
                        © {this.state.currentYear}{" "}
                        <a href="https://portpro.io/" target="_blank">
                          Powered by PortPro.
                        </a>{" "}
                        Made with love.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </footer>
        </div>
        <Modal size="xl" show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Commercial Driver Application</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-0">
            <h6 className="px-3 py-2">
              We are looking for safe, reliable owner operators that want a long
              term relationship. We offer competitive rates, fuel surcharge,
              consistent freight, and a ComData fuel card program.
            </h6>
            <form className="mt-4">
              <div className="px-3">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name*"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name*"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email*"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Phone Number*"
                    />
                  </div>
                </div>
              </div>

              <div className="px-3 pt-4">
                <h6>Do you hold a valid CDL Class A license?</h6>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <select className="form-control">
                      <option>Select</option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="px-3 pt-4">
                <h6>Do you hold a valid TWIC card? </h6>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <select className="form-control">
                      <option>Select</option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="px-3 pt-4">
                <h6>
                  How many years of Tractor Trailer driving experience do you
                  have?
                </h6>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="px-3">
                <div className="form-row">
                  <div className="col-md-12 d-flex justify-content-end mb-0">
                    <button className="btn btn-primary btn-lg">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal size="xl" show={this.state.isOpen2} onHide={this.closeModal2}>
          <Modal.Header closeButton>
            <Modal.Title>Get A Rate Quote</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-0">
            <h6 className="px-3">Please provide all information requested.</h6>
            <form className="mt-4">
              <div className="px-3">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name*"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Company"
                    />
                  </div>

                  <div className="form-group col-md-4">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email*"
                    />
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="px-3 pt-4">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>Shipment Type</label>
                    <select className="form-control">
                      <option>Import</option>
                      <option>Export</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="px-3 pt-4">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>Shipment Pickup Location</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City, State or Zip"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Shipment Delivery Location</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City, State or Zip"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Container Size</label>
                    <select className="form-control">
                      <option>20 ft</option>
                      <option>40 ft</option>
                      <option>45 ft</option>
                      <option>48 ft</option>
                      <option>53 ft</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="px-3">
                <div className="form-row">
                  <div className="col-md-12 d-flex justify-content-end mb-0">
                    <button className="btn btn-primary btn-lg">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default App;
