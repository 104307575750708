import React, { Component } from "react";
import App from  './App';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';






class router extends Component {
  render() {
    return(
      <Router history={browserHistory}>

         {/* A <Switch> looks through its children <Route>s and
             renders the first one that matches the current URL. */}
           <Route path="/" component={App}>
           </Route>
     </Router>
    )
  }
}

export default router;
