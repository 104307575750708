import React, { Component } from "react";

const ContactForm = () => {
  const sendMailContact = () => {
    window.Email.send({
      Host: "https://box5768.bluehost.com:2080",
      Username: "test@itzo.net",
      Password: "1234678",
      To: "umanga.907@gmail.com ",
      From: "test@itzo.net",
      Subject: "This is the subject",
      Body: "And this is the body",
    }).then((message) => alert(message));
  };
  return (
    <div className="section-contact__form">
      <form className="" onSubmit={sendMailContact}>
        <h3 className="section-contact__title section-subtitle">
          Get In <span className="portpro-dot dot-primary">Touch</span>
        </h3>
        <div className="form-row">
          <div className="col-md-6 form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Your Name*"
            />
          </div>
          <div className="col-md-6 form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Phone Number*"
            />
          </div>
          <div className="col-md-6 form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Address*"
            />
          </div>
          <div className="col-md-6 form-group">
            <input className="form-control" type="text" placeholder="Email*" />
          </div>
          <div className="col-md-12 form-group">
            <textarea
              className="form-control"
              type="text"
              placeholder="Message*"
              rows="8"
            />
          </div>
          <div className="col-md-12 form-group text-center">
            <button className="btn btn-main btn-lg btn-block">
              Submit Message
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ContactForm;
